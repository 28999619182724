import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import {Navigation} from "./../Navigation";
import {myConfig} from "../../config";
import {TopBar} from "./../TopBar";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Scrollbars} from "react-custom-scrollbars";
import Switch from "react-switch";
import moment from 'moment';

import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel,} from "./../PostCompnent";
import {Footer} from "./../Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {SupportButton} from "./../SupportButton";
import DateRangePicker from "react-bootstrap-daterangepicker";

export class PaymentLinkSchool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_amount: "",
            payment_amount_print: "",
            payment_link: "",
            payment_reference: "",
            toasts: "",
            payment_link_tray: [],
            payment_link_array_data_count: 0,
            payment_link_array_data_page: 1,
            errors_link: [],
            lkr_available: "",
            usd_available: "",
            gbp_available: "",
            checked: false,
            switchState: {},
            currency_code: "",
            currency_id: 5,
            aud_available: "",
            payment_references_data:[]

        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.setMoney = this.setMoney.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.validateLink = this.validateLink.bind(this);
        this.setMoneyPrint = this.setMoneyPrint.bind(this);
        this.notify = this.notify.bind(this);
        this.switchChange = this.switchChange.bind(this);
        this.setDynamicSwitchState = this.setDynamicSwitchState.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onPrevClick = this.onPrevClick.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getPaymentReferences = this.getPaymentReferences.bind(this);
    }


    handleCallback(start) {

        const expiry_date = moment(start).format("DD-MM-YYYY");
        this.setState({
            expiry_date: expiry_date
        })

    }

    handleChange(e) {

        let emi_only = 0;
        if (e.target.checked) {
            emi_only = 1;
        } else {
            emi_only = 0;
        }
        this.setState({
            emi_only_checkbox: e.target.checked,
            emi_only: emi_only
        })
    }

    notify() {
        toast("Copied to Clipboard");
    }

    onNextClick() {
        if (
            this.state.payment_link_array_data_page <
            this.state.payment_link_array_data_count
        ) {
            this.setState(
                (prevState) => ({
                    payment_link_array_data_page:
                        prevState.payment_link_array_data_page + 1,
                }),
                () => {
                    this.getLinks();

                }
            );
        }
    }

    onPrevClick() {
        if (this.state.payment_link_array_data_page > 1) {
            this.setState(
                (prevState) => ({
                    payment_link_array_data_page:
                        prevState.payment_link_array_data_page - 1,
                }),
                () => {
                    this.getLinks();
                }
            );
        }
    }

    validateLink() {
        let errors_link = {};
        let formIsValid = true;
        //Email
        //const regex = /^[0-9a-zA-Z&.# ,?()-:(\-)]+$/;

        if (
            this.state.currency_id == "" ||
            typeof this.state.currency_id == "undefined"
        ) {
            formIsValid = false;
            errors_link["currency_id"] = "Currency is required";
        }
        if (this.state.payment_amount == "") {
            formIsValid = false;
            errors_link["payment_amount"] = "Payment amount is required";
        }

        if (this.state.payment_reference == "") {
            formIsValid = false;
            errors_link["payment_reference"] = "Payment link description field is required";
        }

        // if (!this.state.payment_reference.match(regex) ) {
        //     formIsValid = false;
        //     errors_link["payment_reference"] = "Only alphanumeric values allowed";
        // }

        this.setState({errors_link: errors_link});
        return formIsValid;
    }

    deleteLink(id) {
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: sessionStorage.getItem("laravel_token"),
            },
        };
        let formData = new FormData(); //formdata object
        formData.append("id", id);
        formData.append("_method", "DELETE");
        this.handleToggleProgress();
        PostWebxLaravel(
            "links/delete_links/" +
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id") +
            "|" +
            id,
            formData
        ).then((response) => {
            this.handleCloseProgress();
            this.getLinks();
        });
    }
    getPaymentReferences(){
        this.handleToggleProgress();
        GetWebxLaravel("payment-reference/get-refs/"+sessionStorage.getItem('merchant_id')).then(response=>{
            if (response){

                this.handleCloseProgress();

                if (response.data !== null) {
                    this.setState(
                        {
                            payment_references_data:response.data
                        }
                    );
                }

            }
        });
    }
    getLinks() {
        this.handleToggleProgress();
        GetWebxLaravel(
            "school_links/get_links/" +
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id") +
            `/${this.state.payment_link_array_data_page}`
        ).then((response) => {
            const {data, link_data_count} = response.data;
            this.handleCloseProgress();
            if (response) {
                this.setDynamicSwitchState(data);
                this.setState(
                    {
                        payment_link_tray: data,
                        payment_link_array_data_count: link_data_count,
                    },
                    () => {
                        this.handleCloseProgress();
                    }
                );
                console.log("this state", this.state);
            }
        });
        let formData = new FormData(); //formdata object
        PostCompnentWebx("getStoreCurrency", formData).then((response) => {
            let LKR = false;
            let USD = false;
            let GBP = false;
            let AUD = false;
            if (response.data.indexOf("LKR") > -1) {
                LKR = true;
            }
            if (response.data.indexOf("USD") > -1) {
                USD = true;
            }
            if (response.data.indexOf("GBP") > -1) {
                GBP = true;
            }
            if (response.data.indexOf("AUD") > -1) {
                AUD = true;
            }
            this.setState({
                lkr_available: LKR,
                usd_available: USD,
                gbp_available: GBP,
                aud_available: AUD,
            });
            if (LKR == true) {
                this.setState({
                    currency_id: 5,
                });
            } else if (USD == true) {
                this.setState({
                    currency_id: 2,
                });
            } else if (GBP == true) {
                this.setState({
                    currency_id: 1,
                });
            } else if (AUD == true) {
                this.setState({
                    currency_id: 6,
                });
            }
        });
        this.getPaymentReferences();
    }

    setMoney(payment_amount, payment_reference, currency_id, currency_code) {

        this.setState(
            {
                payment_amount: payment_amount,
                payment_reference: payment_reference,
                currency_id: currency_id,
                currency_code: currency_code,
            },
            () => {
                this.generateLink();
            }
        );
    }

    setMoneyPrint() {
        this.setState({payment_amount_print: this.state.payment_amount});
    }

    generateLink() {
        let errors_link = {};
        this.setState({errors_link: errors_link});
        if (this.validateLink()) {
            let formData = new FormData(); //formdata object
            let payment_amount = this.state.payment_amount;
            let currency_id = this.state.currency_id;
            let payment_reference = this.state.payment_reference;

            let created_by = sessionStorage.getItem("user_name");

            formData.append("link_amount", payment_amount);
            formData.append(
                "merchant_id",
                sessionStorage.getItem("merchant_id") +
                "|" +
                sessionStorage.getItem("encrypted_merchant_id")
            );
            formData.append("currency_id", currency_id);
            formData.append("payment_reference", payment_reference);
            formData.append("created_by", created_by); //append the values with key, value pair
            formData.append("type", "laptop"); //append the values with key, value pair

            this.handleToggleProgress();
            PostWebxLaravel("school_links/create_link", formData).then((response) => {
                this.handleCloseProgress();

                if (response.data.status == 200) {
                    this.getLinks();
                    this.setMoneyPrint();
                    // let payment_link = myConfig.laravelWeb + 'payment_link/generate_payment/' + response.data;
                    this.setState({
                        payment_link: response.data.data,
                    });
                } else {
                    let errors_link = {};
                    let formIsValid = true;

                    if (response.data.message.payment_reference) {
                        errors_link["payment_reference"] =
                            response.data.message.payment_reference
                                .map((item) => item)
                                .join(", ");
                    }

                    if (response.data.message.link_amount) {
                        errors_link["payment_amount"] = response.data.message.link_amount
                            .map((item) => item)
                            .join(", ");
                    }

                    if (response.data.message.currency_id) {
                        errors_link["currency_id"] = response.data.message.currency_id
                            .map((item) => item)
                            .join(", ");
                    }

                    this.setState({errors_link: errors_link});
                }
            });
        }
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    onChanges(e) {
        if (e.target.name == "currency_id") {
            let selectedText = e.target.options[e.target.selectedIndex].text;
            this.setState({currency_code: selectedText})

        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    switchChange = (index, event, checked, id) => {
        const list = Object.assign({}, this.state.switchState);

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: sessionStorage.getItem("laravel_token"),
            },
        };

        let formData = new FormData(); //formdata object
        formData.append("id", id);
        formData.append("status", event == true ? 1 : 0);
        formData.append(
            "merchant_id",
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id")
        );
        this.handleToggleProgress();
        PostWebxLaravel("links/update_status", formData).then((response) => {
            this.handleCloseProgress();
            this.getLinks();
        });

        list["switch-" + index] = event;

        this.setState({
            switchState: list,
        });
    };

    setDynamicSwitchState = (list) => {
        if (!list) {
            return;
        }

        const switchState = {};

        list.forEach((item, index) => {
            switchState["switch-" + index] = item.is_active == 1 ? true : false;
        });

        this.setState({
            switchState: switchState,
        });
    };

    componentWillMount() {
        if (
            sessionStorage.getItem("userToken") ||
            localStorage.getItem("userToken")
        ) {
            var userToken =
                sessionStorage.getItem("userToken") ||
                localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.getLinks();
        } else {
            this.setState({redirect: true});
        }
    }

    render() {
        var React = require("react");

        var payment_link_tray = this.state.payment_link_tray;
        let payment_link = this.state.payment_link;
        let payment_references_data = this.state.payment_references_data;
        console.log(payment_link);
        if (this.state.redirect) {
            return <Navigate to="/login"/>;
        }
        const renderThumb = ({style, ...props}) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(35, 49, 86, 0.8)",
            };
            return <div style={{...style, ...thumbStyle}} {...props} />;
        };
        const renderThumbOther = ({style, ...props}) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(35, 49, 86, 0.8)",
            };
            return <div style={{...style, ...thumbStyle}} {...props} />;
        };
        const CustomScrollbars = (props) => (
            <Scrollbars
                // renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                renderTrackHorizontal={renderThumbOther}
                {...props}
            />
        );
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <div
                    id="outer-container"
                    className="container-fluid color-orange outer-container-inside-page"
                >
                    <div id="turn-box"></div>
                    <Navigation pathname="payment-links"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="payment-links"/>

                        <div
                            id="activation-modal"
                            className="modal-dialog modal-dialog-centered payment-link-modal plmd"
                            role="document"
                            style={{maxWidth:"75vw"}}
                        >
                            <div className="modal-content">
                                <div className="modal-body modal-type-2">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 lft">
                                                {/*<h3 className="tdh">Invoice Details</h3>*/}

                                                <div
                                                    id="create-new-payment-link-model"
                                                    className="modal-dialog modal-dialog-centered"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-body modal-type-2">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-12 cd-frm">
                                                                        <h3 className="tdh" style={{fontSize: "26px", marginBottom: "0"}}>Generate New Payment Link</h3>

                                                                        <div className="col-12 tdm-in p-0">

                                                                            <div className="form-group"
                                                                                 style={{visibility: "hidden"}}>
                                                                                <h6 className="sh">Currency</h6>
                                                                                <select
                                                                                    name="currency_id"
                                                                                    id="currency_id"
                                                                                    value={this.state.currency_id}
                                                                                    onChange={this.onChanges}
                                                                                    className="form-control"
                                                                                >
                                                                                    {this.state.lkr_available ? (<option
                                                                                        value="5">LKR</option>) : ('')}
                                                                                    {this.state.usd_available ? (<option
                                                                                        value="2">USD</option>) : ('')}
                                                                                    {this.state.gbp_available ? (<option
                                                                                        value="1">GBP</option>) : ('')}
                                                                                    {this.state.aud_available ? (<option
                                                                                        value="6">AUD</option>) : ('')}
                                                                                    {this.state.inr_available ? (<option
                                                                                        value="7">INR</option>) : ('')}

                                                                                </select>
                                                                            </div>
                                                                            <span
                                                                                className="error"
                                                                                style={{color: "red"}}
                                                                            >
                                        {this.state.errors_link["currency_id"]}
                                      </span>
                                                                            <div className="form-group">
                                                                                {/*  <input type="text"
                                                                                       className="form-control"
                                                                                       name="payment_amount"
                                                                                       placeholder="Payment Amount"
                                                                                       value={this.state.payment_amount}
                                                                                       onChange={this.onChanges}/>*/}
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={""}
                                                                                    className="form-control"
                                                                                    name="payment_amount"
                                                                                    placeholder="Payment Amount"
                                                                                    value={this.state.payment_amount}
                                                                                    onValueChange={(values) => {
                                                                                        const {formattedValue, value} =
                                                                                            values;
                                                                                        this.setState({
                                                                                            payment_amount: value,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                                <span
                                                                                    className="error"
                                                                                    style={{color: "red"}}
                                                                                >
                                          {
                                              this.state.errors_link[
                                                  "payment_amount"
                                                  ]
                                          }
                                        </span>
                                                                            </div>


                                                                            <div className="form-group">
                                                                                <select type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Payment reference "
                                                                                        name="payment_reference"
                                                                                        onChange={this.onChanges} value={
                                                                                    this.state.payment_reference
                                                                                }>
                                                                                    <option value="" >-- Select payment reference--</option>
                                                                                    {payment_references_data && payment_references_data.length?(
                                                                                        payment_references_data.map((list, key) =>
                                                                                        {

                                                                                            return (
                                                                                                <option value={list.payment_reference} >{list.payment_reference}</option>
                                                                                            )
                                                                                        })
                                                                                    ):('')
                                                                                    }
                                                                                </select>

                                                                                <span className="error"
                                                                                      style={{color: "red"}}> {this.state.errors_link["payment_reference"]}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        type="button"
                                                                        className="nxt-btn"
                                                                        onClick={this.generateLink}
                                                                    >
                                                                        Generate Link &nbsp;&nbsp;
                                                                        <i className="fas fa-save"></i>
                                                                    </button>

                                                                    {payment_link !== "" ? (

                                                                        <div className="row row_clr dit-in">

                                                                            <div className="col-12 lht">
                                                                                <div className="row" style={{justifyContent:"center"}}>
                                                                                    <h6 className="mt-3 cbcpl">Click below to copy the link</h6>
                                                                                    {/*<div className="col-12 lgo">*/}
                                                                                    {/*    <img src="images/invoice/l1.png" alt="" />*/}
                                                                                    {/*</div>*/}
                                                                                    {/*<div className="col-md-6 col-12">
                                                                                        <p className="qrp">
                                                                                            <NumberFormat
                                                                                                value={(
                                                                                                    Math.round(
                                                                                                        this.state
                                                                                                            .payment_amount_print * 100
                                                                                                    ) / 100
                                                                                                ).toFixed(2)}
                                                                                                displayType={"text"}
                                                                                                thousandSeparator={true}
                                                                                                prefix={
                                                                                                    this.state.currency_code + ' '
                                                                                                }
                                                                                            />
                                                                                        </p>
                                                                                    </div>*/}
                                                                                    <ToastContainer/>
                                                                                    <div className="col-md-6 col-12">
                                                                                        <div className="sd">
                                                                                         {/*   <h5>Click below to copy link</h5>*/}
                                                                                            {/*<FacebookShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <FacebookIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </FacebookShareButton>
                                                                                    <TwitterShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <TwitterIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </TwitterShareButton>

                                                                                    <WhatsappShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <WhatsappIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </WhatsappShareButton>

                                                                                    <ViberShareButton
                                                                                        url={payment_link}>
                                                                                        <ViberIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </ViberShareButton>

                                                                                    <TelegramShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <TelegramIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </TelegramShareButton>
*/}
                                                                                            <CopyToClipboard
                                                                                                size={40}
                                                                                                text={payment_link}
                                                                                            >
                                                                                                <button
                                                                                                    onClick={this.notify}
                                                                                                    className="cpy-btn"
                                                                                                    style={{
                                                                                                        transform: "translateY(1px)",
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="far fa-copy"></i> Copy Link
                                                                                                </button>
                                                                                            </CopyToClipboard>
                                                                                        </div>

                                                                                        {/*<a
                                                                                    href={payment_link}
                                                                                    className="p-btn plpb"
                                                                                    target="_blank"
                                                                                >
                                                                                    Pay Now &nbsp;&nbsp;
                                                                                    <i className="fas fa-credit-card"></i>
                                                                                </a>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 details-box d-inv-box">
                                                        <div className="col-12 dbx-in">


                                                        </div>

                                                        {/*<p className="det-p">*/}
                                                        {/*    {this.state.payment_reference}*/}
                                                        {/*</p>*/}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-8 rht">
                                                <h6><b>Previously Generated Payment Links (Click below to regenerate same link)</b></h6>

                                                <table className="table ins-payment-details-table">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Payment Reference</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col">Total Revenue</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Regenerate</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {payment_link_tray &&
                                                    payment_link_tray.length
                                                        ? payment_link_tray.map(
                                                            (list, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td>  <p>
                                                                            <strong>
                                                                                {
                                                                                    list.payment_reference
                                                                                }
                                                                            </strong>
                                                                        </p></td>
                                                                        <td>

                                                                            {list.get_currency?list.get_currency.code+' ':""}
                                                                            <NumberFormat
                                                                                value={(Math.round(list.link_amount * 100) / 100).toFixed(2)}
                                                                                displayType={
                                                                                    "text"
                                                                                }
                                                                                thousandSeparator={
                                                                                    true
                                                                                }
                                                                                prefix={""}
                                                                            />
                                                                           </td>
                                                                        <td> {
                                                                            list.order_count
                                                                        }</td>
                                                                        <td>
                                                                            {list.get_currency?list.get_currency.code+' ':""}
                                                                            <NumberFormat
                                                                                value={(Math.round(parseFloat(list.order_count)*parseFloat(list.link_amount)* 100) / 100).toFixed(2)}
                                                                                displayType={
                                                                                    "text"
                                                                                }
                                                                                thousandSeparator={
                                                                                    true
                                                                                }
                                                                                prefix={""}
                                                                            />
                                                                            {

                                                                        }</td>
                                                                        <td><label>
                                                                            <Switch
                                                                                key={key}
                                                                                checked={
                                                                                    this.state.switchState["switch-" + key]
                                                                                }
                                                                                onChange={(event, checked) =>
                                                                                    this.switchChange(
                                                                                        key,
                                                                                        event,
                                                                                        checked,
                                                                                        list.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        </label></td>
                                                                        <td><button
                                                                            type="button"
                                                                            className="nt-btn"
                                                                            onClick={() =>
                                                                                this.setMoney(
                                                                                    list.link_amount,
                                                                                    list.payment_reference,
                                                                                    list.currency_id,
                                                                                    list.get_currency ? list.get_currency.code : "",
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa-sharp fa-solid fa-code-compare"></i>

                                                                        </button></td>

                                                                    </tr>

                                                                );
                                                            }
                                                        )
                                                        : ""}
                                                    </tbody>
                                                </table>

                                                <div
                                                    className="rg-container "
                                                    style={{

                                                        marginBottom: "45px",
                                                    }}
                                                >

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            fontWeight: "600",
                                                            padding: "5px 0 0",
                                                        }}
                                                    >
                                                        <div
                                                            id="prev"
                                                            className={ this.state.payment_link_array_data_page === 1?"disable-btn-scl":""}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={this.onPrevClick}
                                                        >
                                                            Prev
                                                        </div>
                                                        <div
                                                            id="next"
                                                            style={{
                                                                cursor: "pointer",
                                                                color:
                                                                    this.state
                                                                        .payment_link_array_data_page ===
                                                                    this.state
                                                                        .payment_link_array_data_count &&
                                                                    "grey",
                                                            }}
                                                            onClick={this.onNextClick}
                                                        >
                                                            Next
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}
